/* eslint-disable react/forbid-prop-types */
import React from 'react'
import moment from 'moment'
import useStyles from './styles'
import { Controller, useFormContext } from 'react-hook-form'
import { DateTimePicker as ReferaDateTimePicker, Typography } from '@refera/ui-web'

import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
  * A component that renders a date time picker.
  *
  * Developer notes:
  * - This component is a wrapper for the Refera DateTimePicker component. You can see more about it at: https://github.com/Refera-Tech/refera-ui/blob/dev/packages/web/src/components/Pickers/DateTimePicker.tsx
  * - This component assumes you are wrapping it with a FormProvider component.
  * - Recommended to use {mode: 'onSubmit'} in the useForm hook. More about useForm at: https://legacy.react-hook-form.com/v6/api/#useForm
  *
  * @param {Object} props - The props for the component.
  * @param {string} props.name - The name of the date time picker.
  * @param {string} props.label - The label for the date time picker.
  * @param {boolean} [props.required=false] - Whether the date time picker is required or not.
  * @param {moment.Moment | string} [props.defaultValue=null] - The default value for the date time picker.
  * @param {string} [props.className=''] - The class name for the date time picker wrapper.
  * @param {Object} [props.rules={}] - The validation rules for the date time picker.
  * @param {Object} [props.referaDatePickerProps={}] - The props for the Refera date picker component.
  * @param {string} [props.referaDatePickerClassName=''] - The class name for the Refera date picker component.

  * @returns {JSX.Element} - The rendered component.

  * @example
  * import React from 'react'
  * import { DateTimePicker } from '_components/inputs'
  * import { FormProvider, useForm } from 'react-hook-form'
  *
  * export function MyComponent() {
  *   const methods = useForm()
  *
  * return (
  *     <FormProvider {...methods}>
  *       <form>
  *         <DateTimePicker
  *           name="myDateTimePicker"
  *           label="My date time picker"
  *           required
  *           referaDatePickerProps={{ disablePast: true }}
  *         />
  *       </form>
  *     </FormProvider>
  *   )
  * }
  *
  * export default MyComponent
 */
function DateTimePicker({
  name,
  label,
  defaultValue = null,
  required = false,
  className = '',
  referaDatePickerClassName = '',
  rules,
  referaDatePickerProps = {},
}) {
  const styles = useStyles()
  const { errors, control } = useFormContext()

  return (
    <div className={classNames(className)}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={
          rules ?? {
            required: { value: !!required, message: 'Este campo é obrigatório' },
            validate: v => {
              try {
                const momentValue = moment(v)
                if (!momentValue?.isValid()) {
                  if (!required) return true
                  return 'Insira uma data válida'
                }
                if (referaDatePickerProps?.disablePast && moment().isAfter(momentValue))
                  return 'A data não pode ser menor que hoje'
                return true
              } catch {
                if (!required) return true
                return 'Insira uma data válida'
              }
            },
          }
        }
        as={
          <ReferaDateTimePicker
            label={label}
            variant="inline"
            required={!!required}
            className={classNames(
              styles.datePicker,
              errors[name] ? styles.inputError : styles.notErrored,
              referaDatePickerClassName
            )}
            format="dd/MM/yyyy  HH:mm"
            placeholder="DD/MM/AAAA HH:MM"
            disabled={false}
            refuse={/[^\d\\.]+/gi}
            error={errors[name]}
            inputProps={{
              required: true,
            }}
            {...referaDatePickerProps}
          />
        }
      />
      <Typography className={styles.errorMessage}>{errors[name]?.message ?? ''}</Typography>
    </div>
  )
}

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  className: PropTypes.string,
  referaDatePickerProps: PropTypes.object,
  referaDatePickerClassName: PropTypes.string,
}

DateTimePicker.defaultProps = {
  defaultValue: null,
  className: '',
  required: false,
  rules: null,
  referaDatePickerProps: {},
  referaDatePickerClassName: '',
}

export default DateTimePicker
